.card {
    background-image: linear-gradient(-45deg, #FF4D38, #F42F54);
    width: 300px;
    height: 185px;
    border-radius: 16px;
    margin-top: 40px;
    
}

.card-logo {
    width: 90px;
    margin-left: 16px;
    margin-top: 16px;
}

.name {
    margin-left: 16px;
    margin-top: 16px;
    margin-bottom: 0px;
    color: #111E6B;
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 1.3em;
    width: 260px;
    height: 50px;
}

.balance {
    position: relative;
    right: 0px;
    margin-top: 0px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.robins-card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin:5px;
    height: 25px;
    
}

.robin-card-coin{
    width: 50px;
    height: 50px;
}

.robin-card-balance{
    color:#FFEA94;
    font-family: "Helvetica", "sans-serif";
    font-size: 24px;
    margin: 0px;
    margin-left: 4px;
    font-weight: bolder;
}

.berry-card-container{
    position: relative;
    width: 45px;
    height: 55px;
    display: flex; 
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.berry-card-img{
   width: 100%;
}

.berry-card-balance{
    position: absolute;
    margin: 0px;
    padding-top:10px;
    color: white;
    font-size: 18px;
    font-weight: bolder;
}