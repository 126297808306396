.background-bar{
    width: 250px;
    height: 8px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;


    box-shadow: 0 0 0  5px #111E6B;
    

    z-index: 1;

}


.progress-bar{
    height: 8px;
    border-radius: 10px;


    box-shadow: 0 0 0  5px #111E6B;

    z-index: 2;
    
    position: relative;
    
}
