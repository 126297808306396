.street-nav{
    width: 100%;
    height: 80px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    border-bottom: 1px solid #D9D9D9;
}

.withdraw-btn{
    background-color: #00CCFF;
    width: 200px;
    height: 35px;
    margin: 0px;
}

.back-left{
    background-color: #00CCFF;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.back-left:hover{
        cursor: pointer;
}

.hud-left{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.street-round{
    font-weight: bold;
    color: navy;
}

.street-timer{
    font-weight: bold;
    color: grey;
    -webkit-animation: street-timer 1s ease-in-out infinite both;
	        animation: street-timer 1s ease-in-out infinite both;
}

@-webkit-keyframes street-timer {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes street-timer {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  
.street-nav-left{
    display: flex;
    flex-direction: row;
}

.street-nav-right{
    width: 300px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    
}



.street-balance{
    text-align: right;
    font-weight: bold;
    font-size: 1.5em;
    color:#3DD869;
}


.back{
    width: 30px;
    height: 30px;
}