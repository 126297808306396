.course-session-box-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    margin: 20px;
    width: 170px;
    height: 186px;
    background-color: white;
    color:#111E6B;
    border-radius: 20px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    border-width: 6px;
    /* border-style: solid; */
    /* border-color: chartreuse; */

/* 
    border: solid 6px;
    border-color: #984EFF; */
}

.session-img{
    max-width: 70px;
    max-height: 70px;
    margin-bottom: 10px;
}

.crouse-session-box-title{
    margin: 10px;
    text-align: left;
    width: 130px;
    /* margin-top: 20px; */
}

.session-complete-img{
    width: 50px;
    height: 50px;
    position: relative;
    /* display: flex; */
    bottom: 10px;
    left: 80px;
}