.market{
        /* min-width: 100%;
        height: 600px; */
        padding-top: 60px;
        padding-bottom: 180px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        overflow-x: auto;
}

@media screen and (max-width: 530px) {
    div.market {
        padding-top: 250px;
        min-width: 100%;
        height: 400px;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: auto;

        margin-right: 20px;
    }
  }

.merchant{
    width: 270px;
    height: 320px;
    background-color: white;
    border-radius: 14px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin: 25px;

    padding-top: 55px;
    margin-top: -30px;
}

.tent-img{
    width: 300px;
    position: relative;
    top: 30px;
    left: 10px;
}

.buy-item{
    background-color: #3DD869;
    width: 150px;
    height: 40px;
}

.merchant-dialogue{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.merchant-img{
    width: 85px;
    height: 85px;
}

.item-offer{
    width: 150px;
    height: 70px;
    background-color: #F0F0F4;
    border-radius: 14px;
    

    display: flex;
    justify-content: center;
    align-items: center;
}

.item-img{
    width: 45px;
    height: 45px;
    margin-right: 5px;
}

.item-price{
    font-weight: bolder;
    font-size: 24px;
    color: #3DD869;
}

.dialogue-bubble{
    width: 16px;
    height: 16px;
    background-color: #F0F0F4;
    border-radius: 0px 0px 0px 4px;
    transform: rotate(45deg);
    position: relative;
    top: 35px;
    right: 157px;

    
}

