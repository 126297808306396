.refer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 65px;
}

.refferal-card{
    width: 350px;
}

.refer-title{
    font-size: 25px;
    width: 80vw;
    margin: 25px;
    text-align: center;
    color: #984EFF;

}

.refer-text{
    width: 80vw;
    color: navy;
    font-size: 20px;
    text-align: center;
}

.refer-card-email{
    font-size: 30px;
    position: relative;
    bottom: 120px;
    color: white;
}