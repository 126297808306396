.nav-bar-container{
    width: 100vw;
    height: 65px;
    background-color: white;
    position: fixed;
    top: 0px;
    z-index: 3;

    border-bottom: 1px solid #D9D9D9;

    display: flex;
    justify-content: center

}
.nav-icon{
    margin: 0px;
    margin-right: 10px;
}

.nav-icon:hover{
    cursor: pointer;
}

.heading{
    font-size: 1.6em;
    color: #111E6B;
    font-weight: bold;
}