.stepper-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 500px;

}

.stepper-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    flex-direction: column;
}


.stepper-controls{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 320px;
}

.stepper-img{
    width: 320px;
    border-radius: 15px;
}

.stepper-text{
    width: 300px;
    text-align: center;
    color: #111E6B;
}

.form-progress-bar{
    position: relative;
    
}

.form-progress-bar{
    width: 300px;
    height: 15px;
    background-color: #D8D8D8;
    border-radius: 20px;
}

.form-progress-indicator{
    width: 0px;
    height: 15px;
    background-color: #3DD869;

    border-radius: 20px;
}

.back-arrow-img{
    width: 50px;
    height:50px;
    /* margin-right: 10px; */
}