.activity-list{
    background-color: #FFFEF5;
    border-radius: 5px;
}


.curricular-activities-view{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.curricular-activities-topic{
    width: 100%;
    font-weight: bolder;
    color:#111E6B;
    font-size: 22px;
    text-align: center;

    margin: 5px;
    margin-top: 85px;
}

.create-quiz{
    background-color: #111E6B;
    color: white;
}

.activity-box{
    background-color: white;
    /* width: 360px;
    height: 60px; */
    border-radius: 5px;
    margin: 10px;
    /* padding: 10px; */
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    height: 60px;
    width: 650px;
}


.activity-box-date{
    position: absolute;
    margin-left: 480px;
    background-color: white;
    padding: 5px;
    font-weight: bolder;
    color: #717171;
}

.activity-box-score{
    position: absolute;
    margin-left: 430px;
    background-color: white;
    padding: 5px;
    font-weight: bolder;
    color: #111E6B
}

.activity-box-berry-counter{
    position: absolute;
    margin-left: 575px;
    display: flex;
}




.top-right-activities-btn{
    position:absolute;
    top: 10px;
    right: 10px;
    
    border-radius: 5px;
    z-index: 4;
}
.other-activities-btn{


    background-color: #3DD869;
    color: white;
    font-weight: bolder;
    font-size: 20px;
    width: 200px;
    height: 45px;
    border-top: white 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0px;
    border-radius: 10px;
}

.activity-box-left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.activity-box-title{
    font-weight: bolder;
    color:#111E6B;
    margin: 0px;
    padding: 10px;
    
    width: 410px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activity-box-title-small{
    font-weight: bolder;
    color:#111E6B;
    margin: 0px;
    padding: 10px;
    
    width: 415px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.activity-box-circle-btn{
    width: 40px;
    height: 40px;
}

.activity-box-circle-btn:hover{
    cursor: pointer;
}

.activity-box-status-tag{
    padding: 5px;
    margin: 8px;
    background-color: white;
    font-size: 14px;
    font-weight: bolder;
    border-radius: 4px;
    color: #111E6B

}

.status-approved{
    background-color: rgb(193, 249, 193);
}

.status-teacher-comment{
    background-color: rgb(255, 255, 197);
}

.status-waiting-approval{
    background-color: rgb(210, 176, 226);
}




