.profile-container{
   height: 100vh;
   display: flex;
   justify-content: space-between;
}

.city-map{
    /* position: absolute;
    top: 7px;
    right: 30px; */
    background-color: #00CCFF;
    width: 45px;
    height:45px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-counters{
    position: absolute;
    top: 0px;
    right: 70px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 240px;
}

.city-map:hover{
    cursor: pointer;
}

.city-map-img{
    width: 45px;
    height: 45px;
}

.town-container{
    padding-top: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-end;
    bottom: 0px;
    height: 100%;
    padding-left: 50px;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 50px;
}


.town-container-mini{
    padding-top: 100px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    bottom: 0px;
    height: 100%;
    padding-left: 50px;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 50px;
}

.town-container-mini .subject-selector-view{
        flex-grow: 1; /* Allows the nested container to grow like the other items */
        min-width: 0; /* Prevents the nested container from breaking the layout */
    
}

.avatar-fixed{
    position: fixed;
    z-index: 3;
   
}

.profile-mini-img{
  width: 25px;
  height: 55px;
  position: relative;
    
}

.profile-mini-agenda{
    width: 55px;
    position: relative;
    margin-left: 10px;
  }

.settings-btn{
    height: 45px;
    width: 45px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.building{
    height: 500px;
    margin-right: 50px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.building-img{
    height: 500px;
    image-rendering: pixelated;
}

.buildingmini{
    height: 350px;
    margin: 10px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.building-imgmini{
    height: 350px;
    image-rendering: pixelated;
}

.top-container{
    width: 100%;
    top: 0;
    height: 390px;
    position: fixed;
    background-image: url("../../assets/images/clouds.png");
    margin-top: 65px;
    left: 0;
    z-index: -1;
    background-size: cover;
}

.logo-nav{
    height: 45px;
    /* position: absolute; */
    left: 5px;
    top: 10px;
}

.top-bar{
    height: 65px;
    width: 100vw;
    position: absolute;
    background-color: white;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.bottom-container{
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 40px;

}

.nav-icon {
    background-color: #00CCFF;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin:20px; */
    margin-left: 20px;
    margin-right: 10px;

}

.back{
    width: 30px;
}

.nav-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    position: absolute;
    left: 10px;
    top: 10px;
    /* width: 70%; */

}

.nav-bar{
    background-color: white;
    height: 70px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.avatar-text{
    text-align: center;
    font-size: 1.3em;
    margin: 15px;
}