.instructions-container{
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #984EFF;
    flex-direction: column;
}

.instructions-text{
    font-family: 'Helvetica', "sans-serif";
    font-size: 24px;
    font-weight: bolder;
    color: white;
    width: 300px;
    margin: 20px;
    text-align: center;
}

.instructions-img{
    max-width: 300px;
    max-height: 300px;
    margin: 20px;
}

.instructions-btn{
    background-color: white;
    color: #984EFF;
    height: 45px;
}