.tutoring-view{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    flex-direction: column;
}

.tutoring-text{
    max-width: 300px;
    color: navy;
    text-align: center;
}

.tutoring-title{
    color: navy;
    color: #00CCFF;
    font-size: 20px;
    margin: 15px;
    text-align: center;
}

.tutoring-registro{
    background-color: #00CCFF;
    font-size: 20px;
    margin: 15px;
}

.tutoring-img{
    max-width: 100px;
    margin: 20px;
}