.toggle-upload-status{
    background-color: white;
    color: #111E6B;
    border-radius: 5px;
}

.student-uploads-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}