.summary-city-box{
    

    color: #111E6B;
    width: 400px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    border-radius: 5px;
    margin: 20px;

}

.city-box-top{
    width: 400px;
    min-height: 130px;
    background-size: cover;
    background-position-y: 80%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 5px 5px 0 0;
}

.visitor-tag-placeholder{
    margin: 10px;
    width: 160px;
    height: 40px;
    padding: 5px;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.4);
}

.city-box-img{
    width: 400px;
    min-height: 120px;
    object-fit: cover;
    position: absolute;
}

.city-name{
    font-size: 25px;
    font-weight: bolder;
}

.summary-city-market{
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: #111E6B;
    width: 400px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

}

.summary-city-stocks{
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    width: 400px;
}

.summary-city-stock-row{
    

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;

    border-bottom: solid 1px #F0F0F4;
    font-weight: bold;
}

.summary-city-stock-name{
    width: 160px;
    padding: 5px;
}

.summary-city-stock-range{
    width: 120px;
    padding: 5px;
}

.summary-city-stock-owned{
    width: 120px;
    padding: 5px;
}

