.sender-img{
    /* width: 60px;
    height: 60px; */
    max-width: 29px;
    max-height: 65px;
    /* border-width: 4px; */
    /* border-style: solid;
    border-color: white;
    border-radius: 50%; */
    margin: 5px;
    /* background-color: white; */
}
.btn-chat-tutor{
    background-color: #00CCFF;
    position: fixed;
    top: 0px;
    width: 200px;
    z-index: 2000;
    right: 0px;
}
.btn-chat-grupal{
    background-color: #984EFF;
    position: fixed;
    top: 0px;
    width: 200px;
    z-index: 2000;
    right: 0px;
}
.message-name-incoming{
    margin-left: 60px;
    margin-top: 20px;
    color:#00CCFF;
   
}

.message-first-outgoing{
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: flex-end;
}

.message-first-robin{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-end;
}

.message-timestamp{
    font-size: 12px;
    color: gray;
    /* color: navy; */
    margin-bottom: 2px;
}

.message-name-robin{
    margin-left: 60px;
    margin-top: 20px;
    color:#FF4D38;
    color:gray;

    max-width: 300px;
    margin-right: 10px;
}

.message-name-outgoing{
    text-align: right;
    margin-right: 40px;
    margin-top: 10px;
    color:#3DD869;
    /* color:navy; */

    max-width: 300px;
    margin-left: 10px;

}

.message-name-teacher{
    margin-left: 60px;
    margin-top: 20px;
    color:#984EFF;
}




.message-container{
    background-color: #3DD869;
    /* background-color: white; */
    min-height: 60px;
    width: 340px;
    padding: 15px;
    word-wrap: break-word;
    border-radius: 10px;
    margin-bottom: 10px;
}

.message-img-container{
    display: flex;
    align-items: center;
    margin-top: 5px;
    justify-content: flex-end;

    /* background-color: white; */
    min-height: 60px;
    width: 340px;
    padding: 15px;
    word-wrap: break-word;
    border-radius: 10px;
    margin-bottom: 10px;
}

.chat-message-container{
    display: flex;
    align-items: flex-end;
    margin-top: 0px;
}

.outgoing{
    justify-content: flex-end;
    /* margin-right: 20px; */
    color: navy;
}

.incoming {
    justify-content: flex-end;
    /* margin-left: 20px; */
    flex-direction: row-reverse;
}

.robin {
    justify-content: flex-end;
    margin-left: 20px;
    flex-direction: row-reverse;
    
}

.teacher {
    justify-content: flex-end;
    margin-left: 20px;
    flex-direction: row-reverse;
    
}

.robin .message-text{
    color: navy;
}

.message-sent-img{
    max-width: 300px;
    max-height: 300px;
    border-radius: 10px;
}

.robin .message-container{
    background-color: white;
    color: navy;
}

.teacher .message-container{
    background-color: #984EFF;
}

.incoming .message-container{
    background-color: white;
}

@media screen and (max-width: 440px) {


    .message-name-robin{
        margin-left: 50px;
    }


    .btn-chat-tutor{
        visibility: hidden;
    }


    .btn-chat-grupal{
        visibility: hidden;
    }

    .message-name-outgoing{
        margin-right: 50px;

    }

    .sender-img{
        width: 45px;
        height: 45px;
    }

    .message-container{
        width: 70%;
    }

    .outgoing{
        margin-right: 8px;
    }
    
    .incoming {
        margin-left: 8px;
    }

    .chat-message-container{
        margin-left: 0px;
        margin-right: 0px;
    }

    .message-sent-img{
        max-width: 250px;
        max-height: 250px;
        border-radius: 10px;
    }

  }
