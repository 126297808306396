.quest{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    margin-top: 25px;
}

.quest-img{
    width: 345px;
    height: 303px;
}

.old-quest-img{
    width: 200px;
    height: 200px;
}

.manager-img{
    width: 345px;
    height: 303px;
}

.manager-scene{
    background-color: rgb(1, 1, 63);
    /* background-image: url('../../assets/images/store-bg.png');
    background-size: 100vw 50vh;
    height: 50vh; */
    display: flex;
    justify-content: center;
}

.store-img{
    height: 50vh;
}

.manager-character{
    width: 58px;
    height: 145px;
}

.manager-office{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    height: 50vh;
    background-image: url('../../assets/images/brickwall.png');
    background-size: 50% 100%;
}

.img-container{
    display: flex;
    align-items: center;
    justify-content: center;
    /*  */
    width: 300px;
    height: 300px;
}

.manager-img-container{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    /*  */
    width: 400px;
    height: 300px;
}

.character-img{
    width: 100px;
    height: 100px;
}

.choice-btn{
    background-color: #00CCFF;
    width: 180px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.bottom-collection{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.btn-collection{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.quest-scene{
    height: 400px;
    width: 100vw;
    background-color: red;
    bottom: 0;
}

.manager-back{
    background-color: #3DD87F;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    /* position: fixed;
    left: 0px;
    top: 0px; */
}

.quest-back{
    background-color: #3DD87F;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    position: fixed;
    left: 20px;
    top: 20px;
}

.quest-quit{
    background-color: #F42F54;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    position: fixed;
    right: 0px;
    top: 0px;
}

.manager-quit{
    background-color: #F42F54;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    position: fixed;
    left: 0px;
    top: 0px;
}

.right-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

