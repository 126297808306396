.school-options-view{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 85px;
    flex-wrap: wrap;
}

.school-options-box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    height: 250px;

    font-size: 20px;
    font-weight: bolder;
    color: navy;
    padding: 10px;
    margin: 10px;

}

.school-options-box:hover{
    cursor: pointer;
}

.school-options-img{
    width: 180px;
    height: 180px;
    margin: 10px;
}