.stock-offer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 280px;
    background-color: white;
    border-radius: 7px;
    border-width: 4px;
    border-color: navy;
    border-style: solid;
    margin: 20px;
    height: 350px;
    align-items: center;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
}

.stock-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.stock-buy-btn:hover{
  cursor: pointer;
}

.stock-buy-btn{
    background-color: #00CCFF;
    width: 115px;
    height: 35px;
    margin-right: 20px;
    border-radius: 5px;
}

.stock-name{
    color: navy;
    font-size: 1.3em;
}

.stock-price{
    color: navy;
    font-size: 1.8em;
}

.stock-value-label{
    margin: 5px;
}

.stock-img{
    max-width: 120px;
    max-height: 65px;
}

.stock-description{
    background-color: #984EFF;
    padding: 10px;
    text-align: center;
    color: white;
    font-size: 0.9em;
    line-height: normal;
    margin-left: 0px;
    height: 100px;
    overflow-y: hidden;
}

.stock-range{
    color:#984EFF;
    font-size: 1.2em;
    margin: 10px;
}

.flip-horizontal-bottom {
	-webkit-animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes flip-horizontal-bottom {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(-180deg);
              transform: rotateX(-180deg);
    }
  }
  @keyframes flip-horizontal-bottom {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(-180deg);
              transform: rotateX(-180deg);
    }
  }



  /*  */

  .swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }


  .scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  