.treehouse{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

/* .nav-left{
    position: absolute;
    left: 10px;
    top: 10px;
    margin: 0px;
} */
.nav-icon{
    /* margin: 0px; */
}