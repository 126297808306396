.language-areas-view{
    /* background-color: #F0F0F4; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 75px;
}

.language-area-box{
    background-color: #111E6B;
    width: 300px;
    height: 80px;
    margin: 10px;
    border-radius: 10px;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.language-area-box:hover{
    cursor: pointer;
}

.language-area-title{
    color: white;
    font-size: 22px;
    font-weight: bolder;
}