

.avatar-background{
  height: 100%; 
  z-index: 3;
}
/* 
.avatar-canvas{
  position: relative;
  z-index: 10;
} */

.avatar-inventory{
  position: fixed;
  position: -webkit-fixed;
  left: 0px;
  z-index: 100
}

.avatar-items{
  /* margin-top: 65px; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: calc(100% - 210px - 65px)
}

.avatar-item{
  position: absolute;
  height: calc(100vh - 320px);
  width: calc(100vh - 320px)/2.2;
  margin-top: 75px;
}

.save-avatar{
  position: sticky;
  top: 100px;
  right: 100px;
}

.edit-avatar-text{
  text-align: center;
  width: 100vw;
  color: navy;
  margin-top: 80px;
}


div.avatar img{
  /* height: 250px;
  width: 113px;
  margin-top: 15px; */

  height: 400px;
}




@media screen and (max-width: 530px) {
  div.avatar img{
    display: none;
  }
}


































/* Trash? below? */




/* div.avatar {
  text-align: center;
}

div.avatar img{
  width: 190px;
  margin-top:40px;
  border-radius: 50%;
  border-style: solid;
  border-color: white;
  border-width: 7px;
  padding: 10px;
  background-color: white;
  margin-right: 40px;
}

@media screen and (max-width: 530px) {
  div.avatar img{
    display: none;
  }
} */