.kid-stats{
    margin-top: 70px;
    width: 720px;
    height: 345px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    flex-wrap: wrap;
}


.profile{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profile-img{
    height: 150px;
    width: 150px;
    border-radius: 50%;
}

.profile-name{
    color:navy;
    font-size: 25px;
    font-family: "Helvetica", "sans-serif";
    font-weight: bolder;
    text-align: center;
    max-height: 60px;
    margin-bottom: 10px;
    margin-top: 5px;
    width: 200px;

}

.currencies{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 200px
}

@media screen and (max-width: 725px) {
    .kid-stats{
        width: 320px;
        height: 700px;
    }
}

