.activity-builder-modal{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0px;
    z-index: 2;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    
}

.activity-modal-bg{
    
    width: 100%;
    height: 100%;
    background-color: rgba(17, 30, 107, 0.9);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-builder{
    width: 700px;
    height: 500px;
    background-color: white;
    border-radius: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    z-index: 2;
    
}


.activity-builder-form{
    width: 700px;
    /* height: 500px; */
    background-color: white;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    z-index: 2;

    /* margin-top: 150px; */

}

.react-datepicker__input-container input {
    width: 130px;
    border: solid 5px #F0F0F4;
}

.activity-form-title{
    color: #111E6B;
    font-weight: bolder;
    font-size: 20px;
}

.activity-builder-input{
    margin: 4px;
}

.activity-form-row{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.activity-builder-cancel{
    background-color: #F42F54;
    width: 120px;
    border-radius: 5px;
    height: 40px;
}

.activity-builder-add{
    background-color: #00CCFF;
    width: 120px;
    border-radius: 5px;
    height: 40px;
}

.activity-builder-cancel:hover{
    cursor: pointer;
}
.activity-builder-add:hover{
    cursor: pointer;
}

.subject-radio-btn {
    background-color: rgb(205, 205, 205);
    height: 35px;
    border-radius: 5px;
    width: 120px;
    font-size: 16px;
    padding: 0px;
    margin: 3px;
    color: #111E6B;
}

.selected-subject{
    background-color: #111E6B;
    color: white;
}

.subject-radio-btn:hover{
    cursor: pointer;
}

.subjects-radio-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.view-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 20px;
}

.view-toggle label {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #cdcdcd;
    color: #111E6B;
    border: 1px solid #111E6B;
    transition: background-color 0.3s;
}

.view-toggle label:first-of-type {
    border-radius: 5px 0 0 5px;
}

.view-toggle label:last-of-type {
    border-radius: 0 5px 5px 0;
}

.view-toggle input[type="radio"] {
    display: none;
}

.view-toggle input[type="radio"]:checked + label {
    background-color: #111E6B;
    color: white;
}

.activity-list {
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.activity-list ul {
    list-style-type: none;
    padding-left: 0;
}

.activity-list li {
    margin-bottom: 5px;
}

