.khan-activities-container{
    margin-top: 95px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.khan-act-box{
    width: 330px;
    height: 95px;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    background-color: white;
    margin: 20px;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

    color: navy;
    font-size: 18px;
    font-weight: bolder;

}

.khan-act-title{
    color: navy;
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 8px;
    width: 250px;
    
}

.khan-act-arrow{
    width: 35px;
    height: 35px;
}

.top-act-title{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 310px;
}

.khan-progress{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 310px;
}