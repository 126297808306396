.seed-container{
    position: relative;
    width: 50px;
    height: 60px;
    display: flex; 
    justify-content: center;
    align-items: center;
}
.seed-img{
   width: 100%;
}

.seed-balance{
    position: absolute;
    margin: 0px;
    padding-top:5px;
    color: white;
    font-size: 18px;
    font-weight: bolder;
}