.address-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 100px;
}

.address-form-img{
    max-width: 200px;

    max-height: 200px;
}