.article-title{
    font-weight: bold;
    color: navy;
    font-size: 20px;
    margin-top: 5px;
    text-align: center;
    padding: 10px;
    height: 40px;
}

.article-img{
    height: 130px;
    margin: 10px;
    max-width: 300px;
}

.article-text{
    margin-bottom: 10px;
    margin: 10px;
    color: rgb(55, 55, 55);
    font-size: 18px;
    text-align: justify;
    text-justify: auto;
    font-weight: bolder;

}

.article{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    border: 0px;
    border-bottom: 4px;
    background-color: white;
    border-radius: 5px;
    margin-top: 20px;
    width: 310px;

}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
