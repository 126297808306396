.inventory{
    bottom: 0px;
    position: fixed;
    width: 100%
}

.avatar-inventory{

}

.inventory-label-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inventory-label-selected{
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    width: 110px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: solid 2px white;
}

.inventory-label-unselected{
    background-color: #F0F0F4;
    border-radius: 10px 10px 0px 0px;
    width: 110px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px white;
}



.item-collection{
    /* min-height: 180px;
    min-width: 100%;*/
    background-color: white; 

    min-width: 100%;
    height: 180px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;

    padding: 10px;

}




.item-container{
    /* min-width: 60px;
    height: 60px;
    background-color: #F0F0F4;
    border-radius: 14px;
    margin: 20px;

    overflow-x: auto; */

    /* background-color: #e74c3c; */
    background-color: #F0F0F4;
    height: 120px;
    width: 120px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar-item-img{
    max-width: 100px;
    max-height: 100px;
}