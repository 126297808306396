.close{
    position: fixed;
    top: 10px;
    left: 10px;
    width: 44px;
    height: 44px;
}

.pricetag-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
}

.pricetag-top-container{
    /* max-width: 400px; */
    width: 270px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.timer{
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    font-size: 25px;
}

.clock-img{
    width: 25px;
    height: 30px;
    margin-right: 8px;
}

.heart{
    height: 30px;
    margin-left: 5px;
}

.receipt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: white;
    width: 155px;
    height: 50px;
    border-radius: 10px;
    font-size: 30px;
    margin: 10px;
}

.receipt-img{
    height: 30px;
    width: 30px;
    margin-right: 8px;
}

.product{
    height: 270px;
    width: 270px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.pricetag-product-img{
    max-height: 220px;
    max-width: 220px;
}

.pricetag-btn{
    width: 125px;
    height: 50px;
    background-color: #3DD869;
    color: white;
}
.next-btn{
    width: 125px;
    height: 50px;
    background-color: #984EFF;
    color: white;
}