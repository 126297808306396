.course-top-button-container{
    display: flex;
    background-color: white;
    justify-content: flex-start;
    align-items: center;
    border: solid 6px;
    margin: 10px;
    font-size: 20px;
    font-weight: bolder;
    color:#111E6B;
    width: 300px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

    
}

.course-top-button-container:hover{
    cursor: pointer;
}

.course-top-button-img{
    max-width: 35px;
    max-height: 35px;
    margin: 10px;
}