.choose-avatar{
  display: flex;
  flex-direction: row;
}

.choose-view{
  margin-top: 125px;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

p{
  /* font-family: 'Helvetica';
  text-align: left;
  line-height: 25px;
  vertical-align: top;
  font-weight: bold; */

  
    /* color: navy; */

}

button {
  color: whitesmoke;
}

button.green {
  background-color: #3DD869;
  margin-top: 20px;
}

button.blue {
  background-color: #00CCFF;
  margin-top: 125px;
  width: 50px;
  height: 50px;
}

div.col-xs-6 {
  text-align: center;
}