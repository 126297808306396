.chore-container{
    font-family: 'Helvetica', 'sans-serif';
    background-color: white;
    border-radius: 16px;
    margin: 10px;
    height: 280px;
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

}

.chore-name{
    color: #00CCFF;
    font-size: 22px;
    font-weight: bolder;
    margin: 0px;
    max-width: 235px;
}

.chore-icon{
    width: 85px;
    height: 85px;
}

.chore-delete{
    width: 45px;
    height: 45px;
}

.chore-bottom-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 235px;

    height: 45px;
    
}

.chore-pay-btn{
    background-color: #00CCFF;
    color: white;
    width: 125px;
    height: 45px;
    margin: 0px;
}