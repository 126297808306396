/* .topics-view{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 75px;
}

.topics-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.extra-toggle-btn{
    
  position:fixed;
  top: 10px;
  right: 100px;
  border-radius: 5px;
  z-index: 4;

  background-color: #F42F54;
  width: 160px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bolder;
  font-size: 20px;
}


.topic-box{
    background-color: white;
    width: 320px;
    height: 80px;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.topic-box:hover{
    cursor: pointer;
}

.topic-img{
    max-height: 40px;
    max-width: 40px;
}

.topic-title{
    font-weight: bolder;
    color:#111E6B;
    margin: 0px;
    margin-bottom: 10px;
    height: 36px;

    width: 250px;
} */


.activity-selector-view{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    /* flex-wrap: wrap; */
    padding-top: 90px;
    background-color: #936840;
    min-height: 100vh;
}

.shelf-spot{
    background-color: #936840;
    padding: 20px;
    padding-bottom: 0px;
    border-left: #D5B38B 20px solid;
    border-bottom: #D5B38B 40px solid;
    border-right: #D5B38B 20px solid;
}

.topics-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 40px;
    background-color: #FFFEF5;
}


.topic-book-background{
    background-color: rgb(78, 13, 139);
    width: 250px;
    height: 320px;
    border-radius: 15px 3px 3px 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.book-top{
    margin-top: 10px;
    width: 225px;
    height: 30px;
    border-radius: 8px 0px 0px 8px;
    background-color: #FFFEF5;
}

.book-cover{
    width: 225px;
    height: 100%;
    background-color:#9747FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.topic-title{

    background-color: #EADAFF;
    border-radius: 5px;
    padding: 10px;
    font-weight: bolder;
    font-size: 1.3em;
    color:#111E6B;
    margin: 10px;
    margin-bottom: 10px;
    height: 5em;
    text-align: center;


    word-wrap: break-word;

    /* height: 80px; */
    text-overflow: ellipsis;
    overflow: hidden;
} 

.topic-progress-container {
    width: 650px;
    background-color: white;
    padding: 15px;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
}

.topic-points {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.topic-points p {
    color: #111E6B;
    font-weight: bold;
    margin: 0;
}

.topic-points {
    color: #111E6B;
    font-size: 14px;
    font-weight: bold;
    margin: 8px 0;
    text-align: center;
}

.book-cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}