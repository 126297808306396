.dialogue-box{
    background-color: #984EFF;
    border-radius: 15px;
    width: 320px;
    height: 120px;
    
    margin-top: 10px;
    color: white;
    padding: 15px;
}

p{
    size: 1em;
    line-height: 120%
}

.arrow{
    margin-top: 0;
    margin-left: 40px;
    width: 20px;
}