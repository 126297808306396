.settings-view{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.oxxo-view{
    background-color: white;
    border-radius: 16px;
    width: 300px;
    height: 370px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    margin: 50px;
}

.oxxo-text{
    width: 235px;
    text-align: center;
    margin: 0px;
}

.conversion{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin: 20px;
}

.peso-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.robin-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.peso-img{
    width: 70px;
    height: 70px;
}

.robin-img{
    width: 70px;
    height: 70px;
}

.robin-amount {
    font-size: 40px;
    color: #F68E00;

}

.peso-input{
    border-radius: 7px;
    width: 70px;
    margin: 0px;
    margin-top: 5px;
    padding: 5px;
}

.arrow-img{
    width: 30px;
    margin: 10px;
    margin-top: 20px;

}

.oxxo-deposit{
    background-color: #3DD869;
    width: 185px;
    height: 40px;
}




.subscription-view{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    
    background-color: white;
    border-radius: 16px;
    width: 300px;
    height: 370px;
}

.mascot{
    width: 170px;
    margin-top: 30px;
}

.cancel-btn{
    background-color: transparent;
    color:#F42F54;
    font-weight: 400;
}



.sus-text{
    margin-top: 20px;

    width: 235px;
    text-align: center;
}











.opps {
	width: 496px; 
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0 45px;
	margin: 40px auto;
	/* overflow: hidden; */
	border: 1px solid #b0afb5;
	font-family: 'Open Sans', sans-serif;
    color: #4f5365;
    margin-top: 70px;
    background-color: white;
}

.opps-reminder {
	position: relative;
	top: -1px;
	padding: 9px 0 10px;
	font-size: 11px;
	text-transform: uppercase;
	text-align: center;
	color: #ffffff;
	background: #000000;
}

.opps-info {
	margin-top: 26px;
	position: relative;
}

.opps-info:after {
	visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;

}

.opps-brand {
	width: 45%;
	float: left;
}

.opps-brand img {
	max-width: 150px;
	margin-top: 2px;
}

.opps-ammount {
	width: 55%;
	float: right;
}

.opps-ammount h2 {
	font-size: 36px;
	color: #000000;
	line-height: 24px;
	margin-bottom: 15px;
}

.opps-ammount h2 sup {
	font-size: 16px;
	position: relative;
	top: -2px
}

.opps-ammount p {
	font-size: 10px;
	line-height: 14px;
}

.opps-reference {
	margin-top: 14px;
}

h1 {
	font-size: 27px;
	color: #000000;
	text-align: center;
	margin-top: -1px;
	padding: 6px 0 7px;
	border: 1px solid #b0afb5;
	border-radius: 4px;
	background: #f8f9fa;
}

.opps-instructions {
	margin: 32px -45px 0;
	padding: 32px 45px 45px;
	border-top: 1px solid #b0afb5;
	background: #f8f9fa;
}

ol {
	margin: 17px 0 0 16px;
}

li + li {
	margin-top: 10px;
	color: #000000;
}

a {
	color: #1155cc;
}

.opps-footnote {
	margin-top: 22px;
	padding: 22px 20 24px;
	color: #108f30;
	text-align: center;
	border: 1px solid #108f30;
	border-radius: 4px;
	background: #ffffff;
}