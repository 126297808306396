
.top-berries{
    position: fixed;
    z-index: 4;
    top: 2px;
    right: 10px;
}

.store{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

.store-item{
    display: flex;
    height: 360px;   
    width: 260px;
    background-color: white;
    border-radius: 16px; 
    margin: 20px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.product-img{
    max-width: 190px;
    max-height: 150px;
}

.product-name{
    color: #111E6B;
    width: 220px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.product-price{
    color: #F42F54;
    font-weight: bold;
    font-size: 1.2em;
}

.buy-btn{
    background-color: #3DD869;
    width: 200px;
}