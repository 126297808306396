
.curricular-activity-view{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    height: 100vh;
    padding-top: 85px;
    background-color: #936840;
}

.activity-description-box{
    width: calc(100vw - 400px);
    min-width: 320px;
/* width should be calc 100vw - whatever the width from right side*/
    min-height: 150px;
    background-color: white;
    border-radius: 10px;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
}

.activity-view-box-title{
    width: 100%;
    text-align: left;
    font-weight: bolder;
    margin-bottom: 10px;
    font-size: 22px;
    color:#111E6B;
    margin-top: 0px;
}

.activity-view-description{
    font-weight: bolder;
    color:#505050;
}

.activity-box-right-top{
    width: calc(100vw - 420px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.start-activity-btn{
    background-color: #984EFF;
    color: white;
    border-radius: 5px;
    width: 280px;
    margin: 0px;
    height: 45px;
    margin-top:15px;
    font-size: 20px;
}
.start-activity-btn:hover{
    cursor: pointer;
}

.upload-activity-btn{
    background-color: #3DD869;
    color: white;
    border-radius: 5px;
    width: 280px;
    margin: 0px;
    height: 45px;
    font-size: 18px;
}



.activity-view-right{
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.activity-uploads-title{
    padding-top: 10px;
    padding-left: 10px;
}

.activity-delivery-box{
    /* padding: 10px; */
    width: 320px;

    /* min-height: 100px; */
    background-color: white;
    border-radius: 10px;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

.activity-uploads-container{
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 5px;
    width: 300px;

}

.uploaded-img{
    /* max-width: 100px;
    max-height: 100px; */
    width: 100%;
    border-radius: 5px;
}

.uploaded-img:hover{
    cursor: pointer;
}

.activity-comment-box{
    padding: 10px;
    width: 100%;
    height: 100%;
    /* background-color: white;
    border-radius: 10px;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2); */

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 15px;
}

.add-task-top-right{
    position: fixed;
    display: flex;
    top: 10px;
    right: 10px;
    background-color: #0cf;
    border-radius: 5px;
    z-index: 4;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
}

.add-task-top-right-img{
    height: 45px;
    width: 45px;
}

.add-task-top-right-text {
    font-weight: bolder;
    font-size: 20px;
    color: #fff;
    margin: 0;
}


/* Berry Counter */


@media screen and (max-width: 650px) {
    .activity-description-box{
        margin-bottom: 20px;
    }

    .activity-box-right-top{
        width: 100%;
    }
    .course-top-button-container{
        margin: 0px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

}