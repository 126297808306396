.projects-library-view{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 70px;
    flex-wrap: wrap;
}

.project-box-img{
    width: 310px;
    height: 200px;
    border-radius: 5px 5px 0px 0px;
}

.project-box-bottom{
    background-color: white;
    height: 60px;
    width: 310px;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 10px;
}

.project-box-top-overlay{
    background-color: white;
    height: 60px;
    width: 310px;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 10px;
    position: absolute;
    margin-bottom: 210px;
    background-color: transparent;
}

.project-title{
    height: 40px;
    font-weight: bolder;
    font-size: 18px;
    color: #111E6B;
    margin: 0px;
    text-align: left;
    vertical-align: top;

}
.project-box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    height: 260px;
}

.project-age{
    font-size: 16px;
    font-weight: bolder;
    color: #111E6B;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    margin: 0px;
}

.project-time{
    font-size: 16px;
    font-weight: bolder;
    color: #111E6B;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    margin: 0px;
    margin-left: 10px;
}

.project-box:hover{
    cursor: pointer;
}

.project-box-circle-btn{
    border-radius: 50px;
    margin-left: 5px;
    max-width: 40px;
    max-height: 40px;
}