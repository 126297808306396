.grade-selector{
    position:fixed;
    top: 10px;
    right: 10px;
    background-color: #3DD869;
    border-radius: 5px;
    z-index: 4;
}

.grade{
    
    color: white;
    font-weight: bolder;
    font-size: 20px;
    width: 80px;
    height: 35px;
    border-top: white 1px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    
}

.grade-selector-bg{
    width: 100vw;
    height: 100vh;
    background-color: red;
}

.selected-grade{
    
    color: white;
    font-weight: bolder;
    font-size: 20px;
    width: 80px;
    height: 35px;
    border-top: white 1px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    
}

.level-selector{
    position:fixed;
    top: 10px;
    right: 10px;
    background-color: #3DD869;
    border-radius: 5px;
    z-index: 4;
}

.level{
    
    color: white;
    font-weight: bolder;
    font-size: 20px;
    width: 160px;
    height: 35px;
    border-top: white 1px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    text-transform: capitalize;
    
}

.level-selector-bg{
    width: 100vw;
    height: 100vh;
    background-color: red;
}

.selected-level{
    text-transform: capitalize;
    color: white;
    font-weight: bolder;
    font-size: 20px;
    width: 160px;
    height: 35px;
    border-top: white 1px solid;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    
}

