.game-btn{
    width: 100px;
    height: 100px;
    background-color: purple;
    margin: 20px;
    border-radius: 50px;
}

.game-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
}

.game-text{
    width: 250px;
    text-align: center;
}

.game-buttons {
    display: flex;
    justify-content: center;
    flex-direction: row;
   
}