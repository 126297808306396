.chore-view{
    margin-top: 70px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.chore-explanation{
    font-weight: bolder;
    font-size: 1.3em;
    color: navy;
    width: 80%;
    margin: 10px;
    text-align: center;

}

.ask-chore-btn{
    max-width: 250px;
    max-height: 280px;
    border-radius: 15px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 10px;

}