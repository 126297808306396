.street-nav{
    width: 100%;
    height: 65px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0px;
    border-bottom: 1px solid #D9D9D9;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}
.wallstreet-balance-container{

        
        background-color: white;
    
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
}
    .wallstreet-balance-img{
        width: 45px;
        margin: 5px;
    }
    
    .wallstreet-balance{
        color: #3DD869;
        font-size: 24px;
        font-weight: bolder;
    }
    

.withdraw-btn{
    background-color: #00CCFF;
    width: 200px;
    height: 35px;
    margin: 0px;
}


.hud-left{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.street-round{
    font-weight: bold;
    color: #111E6B;
}

.street-timer{
    font-weight: bold;
    font-size: 16px;
    color: #111E6B;
    -webkit-animation: street-timer 1s ease-in-out infinite both;
        animation: street-timer 1s ease-in-out infinite both;
}

.street-time{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    color: #111E6B;
    font-weight: bolder;
}

.nav-city-title{
    font-weight: bold;
    font-size: 20px;
    color: #111E6B;
}

.hud-subtitle{
    color: gray;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}

.street-time-counter{

    font-weight: bolder;
    font-size: 18px;
    color: #F42F54;
    -webkit-animation: street-timer 1s ease-in-out infinite both;
    animation: street-timer 1s ease-in-out infinite both;
    text-align: center;
            
}

@-webkit-keyframes street-timer {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes street-timer {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  
.wallstreet-nav-left{
    display: flex;
    flex-direction: row;
}

.wallstreet-nav-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
}


