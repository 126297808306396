.stock{
    width: 140px;
    height: 195px;
    background-color: white;
    border-radius: 8px;
    border-style: solid;
    border-color:navy;
    border-width: 4px;
    margin: 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.stock-sell-btn{
        background-color: #3DD869;
        height: 35px;
        width: 100px;
        border-radius: 5px;
    }
    
    .stock-sell-btn:hover{
        cursor: pointer;
    }

.stock-logo{
    width: 80px;
    margin-top: 20px;
}

.stock-value{
    font-size: 1.5em;
    text-align: center;
}

.stock-portfolio{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 80px;
    padding-bottom: 80px;
}

.portfolio-placeholder{
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px; */
    
    text-align: center;
    margin: auto;
    margin-top: 100px;
    width: 250px;
    padding-bottom: 80px;
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
