.level-container{
    width: 170px;
    height: 170px;

    border: 6px solid #984EFF;
    border-radius: 16px;
    margin: 20px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: 'Helvetica', 'sans-serif';
    font-weight: bolder;
    font-size: 30px;
    color: #984EFF;

}

.level-play{
    background-color: #984EFF;
    height: 45px;
    width: 138px;

    display: flex;

    justify-content: space-evenly;
    align-items: center;
    border-radius: 25px;

    font-family: 'Helvetica', 'sans-serif';
    font-weight: bolder;
    font-size: 20px;
    color: white;


}


.level-grade{
    width: 90px;
    height: 30px;;
}