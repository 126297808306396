.team-picker-view{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}


.teams{
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    color: navy;
    font-weight: bolder;
}

.teams-text{
    margin: 10px;
    text-align: center;
    font-size: 25px;
}

.teams-gameID{
    margin: 10px;
    text-align: center;
    font-size: 30px;

    font-weight: bolder;
}

.team-box{
    border-radius: 10px;
    background-color: white;
    color: navy;
    width: 450px;
    height: 290px;
    margin: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    font-weight: bolder;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

}


.team-players{
    border-radius: 10px;
    width: 450px;
    height: 250px;
    background-size: cover;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 5px;
}


.team-name{
    margin-top: 10px;
    font-size: 25px;
}

.player-tag{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    background-color: white;
    border-radius: 50px;
    margin: 10px;
    max-width: 160px;
    max-height: 40px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.player-img{
    max-width: 30px;
    max-height: 30px;
    margin-left: 5px;
    margin-right: 5px;
}

.player-name{
    max-width: 120px;
    max-height: 20px;
    overflow:hidden;
}

