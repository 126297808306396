.quiz-container{
    display: flex;
    justify-content: center;
    align-items: center;
    
    flex-wrap: wrap;
}

.quiz-view{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 65px;
}

.quiz-question{
    color: navy;
    font-size: 25px;
    font-weight: bolder;
    margin-top: 20px;
    width: 80vw;
}

.quiz-answer-container{
    margin: 10px;
}

.quiz-answer{
    width: 340px;
    min-height: 60px;
    margin: 10px;
    background-color: #00CCFF;
    color: #111E6B;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bolder;
    padding: 10px;
    

}

.quiz-img{
    max-height: 225px;
    max-width: 225px;
    margin: 10px;
}

/* Cuanto es pantalla pequeña */
@media screen and (max-width: 340px) {
    .quiz-answer{
        width: 300px;
    }
  }