
.store{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    padding-top: 70px;
}

.store-item{
    display: flex;
    height: 350px;   
    width: 260px;
    background-color: white;
    border-radius: 16px; 
    margin: 20px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.product-img{
    max-width: 190px;
    max-height: 190px;
}

.product-name{
    color: #984EFF;
    width: 220px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: bold;
}

.product-price{
    color: #F42F54;
    font-weight: bold;
    font-size: 1.2em;
}

.buy-btn{
    background-color: #00CCFF;
    width: 200px;
}

.referral-code{
    display: flex;
    height: 350px;   
    width: 260px;
    background-image: linear-gradient(-45deg, #984EFF, #00CCFF);
    border-radius: 16px; 
    margin: 20px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    color: navy;
    font-weight: bolder;
}

.referral-gift-img{
    width: 70px;
    height: 70px;
}

.referral-text{
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    
    
}

.code-container{
    background-color: white;
    height: 40px;
    width: 100px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
}

.referral-bottom{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 260px;
}