button {
    border-radius: 30px;
    width: 250px;
    height: 45px;
    color: white;
    border-style: none;
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 1.3em;
    margin: 10px;
    text-transform: capitalize;
    background-color: white;
    padding-left: 10px;
    vertical-align: bottom;
}

.form {
    height: 400px;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.small {
    width: 120px !important;
}

.button-yellow {
    background-color: #FFD100;
}

.button-red {
    background-color: #F42F54;
}

.button-blue {
    background-color: #00CCFF;
}

.button-purple {
    background-color: #984EFF;
}

.button-green {
    background-color: #3DD87F;
}

.button-orange {
    background-color: #FF4D38;
}

.button-default {
    background-color: gray;
}