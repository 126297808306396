.agenda-task{
    background-color: #FCF8D7;
    margin-bottom: 15px;
    border-radius: 5px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 60px;
    width: 100%;
}


.row{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
}
.row:hover{
    cursor: pointer;
}

.view-activity-btn{ 
    border-radius: 5px;
    background-color: #984EFF;
    width: 100px;
    margin: 0px;
    height: 40px;

    padding: 10px;
}

.view-activity-btn:hover{
    cursor: pointer;
}


.agenda-task-name {
    font-weight: bolder;
    color: #111E6B;
    max-width: 300px;
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    word-wrap: break-word;

    /* height: 80px; */
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    line-height: 1.2em; /* Adjust based on your font size */
    max-height: 2.9em; /* 2 * line-height */
  }

.agenda-task-circle-btn{
    width: 40px;
    height: 40px;
}

.agenda-task-circle-btn:hover{
    cursor: pointer;
}

.clock-icon{
    max-height: 20px;
    
    margin: 5px;
}

.activity-time{
    font-weight: bolder;
    color: #717171;
    margin: 0px;

    max-width: 80px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Berry Counter */

.berry-counter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 5px;
    height: 40px;
    /* width: 85px; */
    background-color: #DBC1FF;
    border-radius: 100px;
    box-shadow: inset 0px 0px 0px 3px #984EFF;
    box-sizing: border-box;
}

.berry-counter-img{
    width: 40px;
    height: 40px;
    background-color: #DBC1FF;
    border: solid 3px #984EFF;
    padding: 3px;
    border-radius: 50px;
    object-fit: scale-down;
}

.berry-reward-input{
    width: 55px;
    font-family: "Helvetica", "sans-serif";
    margin: 0px;
    border-radius: 50px;
    background-color: transparent;
    border: none;
    position: inherit;
    height: 40px;
    font-size: 18px;
    font-weight: bolder;
    text-align: left;
    color: #7900DD;
}

.robin-reward-input {
    color: #F68E00;
}

.disabled-berry-input{
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 3px transparent;
    border: transparent;
}
