.menu{

        /* min-width: 100%;
        height: 500px; */
        padding-top: 80px;
        padding-bottom: 180px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        overflow-x: auto;
    
}

@media screen and (max-width: 530px) {
    div.menu {
        padding-top: 150px;
        min-width: 100%;
        height: 400px;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: auto;

        margin-right: 20px;
    }
  }

.recipe{
    background-color: white;
    width: 275px;
    height: 185px;

    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 14px;
    margin: 20px;
    padding: 5px;


    
}

.recipe-img{
    height: 90px;
    width: 90px;
    margin-left: 10px;
}

.recipe-price{
    margin-right: 10px;
    color: #3DD869;
    font-size: 22px;
}

.top-recipe{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

}
.bottom-recipe{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Helvetica', 'sans-serif';
    font-size: 22px;
    font-weight: bold;
    color: #111E6B;

}