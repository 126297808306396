.selected-ingredient-container{
   
    background-color: #F0F0F4;
    height: 170px;
    width: 170px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected-ingredient-img{
    width: 120px;
    height: 120px;
    position: absolute;
}

.selected-ingredient-return{
    width: 45px;
    height: 45px;
    position: relative;
    bottom: 75px;
    left: 75px;
}

@media screen and (max-width: 530px) {
    .selected-ingredient-container{
        width: 85px;
        height: 85px;
    }

    .selected-ingredient-img{
        width: 60px;
        height: 60px;
    }

    .selected-ingredient-return{
        width: 22px;
        height: 22px;
        bottom: 40px;
        left: 40px;
    }
}