.project-view-container{
    /* display: flex; */

    /* justify-content: center; */
    /* align-items: center; */
    margin: auto;
    max-width: 770px;
    margin-top: 70px;
}

.projects-collection{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 80px;
}
