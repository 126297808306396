.friend-list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.discord-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 85px;
}

.friend-card{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;


    background-color: white;

    width: 250px;
    height: 100px;
    margin: 20px;
    border-radius: 10px;

}

.friend-img{
    width: 50px;
    height: 50px;
    margin-left: 15px;
    margin-right: 10px;
    border-radius: 50px;
}

.friend-name{
    color: navy;

    margin: 5px;
    width: 120px;
    height: 40px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: wrap;
}

.friend-list-message{
    color: navy;
    text-align: center;
    margin: 5px;
    width: 300px;
    margin-top: 100px;
}