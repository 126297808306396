input {

    border-radius: 8px;
    width: 250px;
    height: 40px;
    color: navy;
    border-style: solid;
    border-width: 4px;
    font-family: 'Helvetica';
    font-weight: bold;
    font-size: 1em;
    margin: 10px;
    background-color: white;
    padding-left: 10px;
}

input::placeholder {
    color: gray;

}

.field-yellow {
    border-color: #FFD100;
}

.field-red {
    border-color: #F42F54;
}

.field-blue {
    border-color: #00CCFF;
}

.field-purple {
    border-color: #984EFF;
}

.field-green {
    border-color: #3DD87F;
}

.field-orange {
    border-color: #FF4D38;
}

.field-default {
    border-color: gray;
}