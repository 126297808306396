.skill-name{
    margin-bottom:7px;
    margin-top: 0px;
    font-family: "Helvetica", sans-serif;
    color: #111E6B;
    font-weight: bold;
    font-size: 1.2em;
    
}

.skill-bar{
    margin-bottom: 25px;
    width: 250px;
}

