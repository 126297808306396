.activity-comment-section{
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-bottom: 70px;
    background-color: #CFBE9E;
    padding: 10px;
    border-radius: 5px;
    overflow-y: overlay;
}

.comment-avatar{
    width: 50px;
    margin-right: 5px;

}

.activity-comment-box-aux{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.activity-comment-section-bottom{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* border-top: #AFAFAF 1px solid; */
    padding-top: 8px;
    margin-top: 8px;
    width: 100%;
    height: 100%;
}

.comment-input{
    height: 45px;
    width: 100%;
    min-width: 100px;
    border-radius: 5px;
    border: none;
    background-color: #FFFEF5;

    border-radius: 5px 0px 0px 0px;
    margin: 0px;
}

.comment-send{
    background-color: #FFCD29;
    width: 80px;
    border-radius: 0px 5px 5px 0px;
    height: 45px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 14px;
    color: navy;


}

.comment-send p{
    font-size: 10px;
}