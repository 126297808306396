.gourmet-tab{
    width: 175px;
    height: 75px;
    border-radius: 0px 0px 16px 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
}

.tab-img{
    width: 45px;
    height: 45px;
}

.tab-name{
    color: white;
    padding: 20px;
}


/* Cuanto es pantalla pequeña */
@media screen and (max-width: 830px) {
    div.gourmet-tab {
        width: 68px;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .tab-img{
        width: 40px;
        height: 40px;
    }
    
    .tab-name{
        color: white;
        font-size: 12px;
        padding: 0px;
    }
  }