.skillgrades{
    display: flex;
    width: 650px;
    justify-content: flex-start;
    align-items:center;
    flex-direction: column;
    flex-wrap: wrap;
    height: 230px;
}


@media screen and (max-width: 600px) {
    

    .skillgrades{
        width: 300px;
        height: 400px;
    }
}