.ingredient-container{
    /* min-width: 60px;
    height: 60px;
    background-color: #F0F0F4;
    border-radius: 14px;
    margin: 20px;

    overflow-x: auto; */

    /* background-color: #e74c3c; */
    background-color: #F0F0F4;
    height: 60px;
    width: 60px;
    margin: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ingredient-img{
    width: 40px;
    height: 40px;
}