.evidence-upload-container{
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 6px #00CCFF;
    border-radius: 16px;
    flex-direction: column;
    background-color: white;
    margin: 20px;
}
.evidence-img{
    max-width: 230px;
    max-height: 96px;
    border-radius: 4px;
}

.evidence-cancel-btn{
    border: solid 6px #F42F54;
    border-radius: 50px;
    background-color: white;
    color: #F42F54;
    width: 190px;
    height: 50px;
    margin: 8px;

}

.evidence-upload-btn{
    background-color: #00CCFF;
    color: white;
    width: 190px;
    height: 50px;
    margin: 0px;
}

