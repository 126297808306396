.lms-avatar{
    width: 150px;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 100;
}


.subject-selector-view{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    
}

.subject-selector-viewmini{
    display: contents;
    flex-wrap: wrap;
}

.topic-selector-view{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 90px;
    background-color: #FFFEF5;
    height: 100vh;
}

.subject-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    background-color: white;
    border-radius: 10px;
    width: 300px;
    height: 240px;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

    font-size: 20px;
    font-weight: bolder;
    color: #111E6B;
    margin: 10px;
}

.subject-box:hover{
    cursor: pointer;
}

.subject-img{
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
}

.subject-box-title{
    margin: 0px;
    margin-bottom: 8px;

}