.house-background{
    background-image: url('../../../assets/images/store-bg.png');
    background-color: red;
    
    /* height: 100vh; */
    /* overflow-y: scroll; */
}

.house-background-{
    background-color: black;
}

.buffer{
    /* width: 100vw; */
    height: 100vh;
    background-color: none;
    pointer-events: all;
    /* touch-action: pan-x; */
    /* scroll-behavior: unset; */
    /* overflow-y: unset; */
    z-index: 90;
    position: absolute;
}

.house-inventory{
    bottom: 0;
    z-index: 1;
    position: static;

}

html, body { 
    width:100%; 
    height:100%; 
    margin: 0;
    padding: 0;
    
    
}

body {
    margin: 0
}

.background-img{
    height: 100vh;
    position: fixed;
}

.canvas{
    z-index: 10;
    display: block;
    margin: 0;
    padding: 0;
}

canvas{
    display: block;
    z-index: 10;
    position: relative;
    /* background-color: transparent; */
}

.coin-img{
    /* width: 2314px; */
    position: absolute;
    bottom: 0;
    /* top: 370px; */

}

.edit-btn{
    /* width: 50px; */
    height: 50px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 100;
}

.inventory-btn{
    height: 50px;
    position: fixed;
    position: -webkit-fixed;
    right: 10px;
    z-index: 100;
}

.save-btn{
    width: 50px;
    height: 50px;
    position: fixed;
    top: 10px;
    right: 70px;
    z-index: 100;
}

.house-icon{
    width: 50px;
    height: 50px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 100;
    background-color: #00ccff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.house-back{
    width: 35px;
    height: 35px;
}
