.street-tabs{
    background-color: white;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    border-top: 1px solid #D9D9D9;
}

.street-tab{
    margin-top: 4px;
    width: 90px;
}

.street-tab:hover{
    cursor: pointer;
}