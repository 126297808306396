.character{
    width: 100px;
    height: 100px;
}

.options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bottom-dialogue {
    display: flex;
    justify-content: space-between;

}

.option-btn{
    background-color: white;
    border-color: #00CCFF;
    border-style: solid;
    color: #00CCFF;
    border-width: 4px;
    width: 175px;
    height: 45px;
    margin:0px;

}