.avatar-picker{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}
.avatar-option{
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border-style: solid;
    border-width: 7px;
    border-color: navy;
    padding: 10px;
    margin: 20px;
    background-color: white;
}

