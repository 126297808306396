.city-boxes-container{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.city-picker-view{
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    color: navy;
    font-weight: bolder;
}

.city-picker-text{
    margin-top: 60px;
    text-align: center;
    font-size: 25px;
}

.city-box{
    border-radius: 10px;
    background-color: white;
    width: 450px;
    height: 290px;
    margin: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);


}

.city-visitors{
    border-radius: 10px;
    width: 450px;
    height: 250px;
    background-size: cover;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 5px;
}

.city-name{
    margin-top: 10px;
    font-size: 25px;
}

.visitor-tag{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;

    border-radius: 5px;
    margin: 10px;
    max-width: 180px;
    max-height: 110px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    
}

.visitor-img{
    max-width: 50px;
    max-height: 100px;
    margin-left: 5px;
    margin-right: 5px;
    object-fit: cover;
    object-position: top;
}

.visitor-name{
    max-width: 130px;
    max-height: 25px;
    overflow:hidden;

    padding: 5px;
    border-radius: 20px 20px 20px 5px;
    background-color: white;
    padding-left: 7px;
    padding-right: 9px;
    margin-top: 15px;
}

