.comment-top{
    color: navy;
    font-weight: bolder;
    /* margin-left: 38px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
    margin-top: 15px;
}

.comment-name{
    margin: 0px;
    margin-right: 10px;
}

.comment-timestamp{
    font-size: 12px;
    color: gray;
    margin-bottom: 2px;
}



.comment-message-container{
    /* display: flex; */
    /* align-items: flex-start; */
    margin-top: 0px;
    margin: 0px;
    /* justify-content: flex-start; */
    /* margin-left: 20px; */
    /* flex-direction: row-reverse; */
}

.comment-container{
    background-color: #FFFEF5;
    width: 235px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

}
.comment-container p {
    color: #00126F
}

.comment-text{
    margin: 0px;
    color: white;
    font-weight: bolder;
}

.comment-img{
    max-width: 29px;
    max-height: 65px;
    margin: 5px;
}