.inventory{
    bottom: 0px;
    position: fixed;
    width: 100%
}

.inventory-label{
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    width: 110px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ingredient-collection{
    /* min-height: 180px;
    min-width: 100%;*/
    background-color: white; 

    min-width: 100%;
    height: 180px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: auto;

    padding: 10px;

    /* display: flex;
    justify-content: center;
    flex-wrap: no-wrap;
    flex-direction: row; */
}