.berry-container{
    position: relative;
    width: 50px;
    height: 60px;
    display: flex; 
    justify-content: center;
    align-items: center;
}
.berry-img{
   width: 100%;
}

.berry-balance{
    position: absolute;
    margin: 0px;
    padding-top:10px;
    color: white;
    font-size: 18px;
    font-weight: bolder;
}

.berry-counter-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 55px;
    width: 75px;
}


.berry-counter-img{
    width: 45px;
    height: 45px;
}

.berry-counter-text{
    color: #FCF8D7;
    font-size: 18px;
    font-weight: bolder;
    margin: 0px;
}