/* .range-img{
    width: 300px;
    height: 300px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    border-radius: 16px;
    margin-top: 30px;
} */

/* .range-view{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
} */
.news-view{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 80px;
}

/* @media only screen and (min-width: 600px) {
    .article-view{
        width: 50%;
        height: 100vh;
    }

    .range-view{
        width: 50%;
        
    }
} */

.article-view{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}





