.video-modal-bg{
    background-color: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1000;
}

.video-modal{
    width: 90vw;
    height: 50vh;
    border-radius: 20px;
}

.modal-quit{
    width: 50px;
    height: 50px;
    position: fixed;
    top: 20px;
    right: 20px;
}

.close-video-modal{
    margin: 30px;
    background-color: #FF4D38;
}