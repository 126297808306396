.modal-background{
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;

    display: flex;
    justify-content: center;
    align-items: center;
    
}


.modal-box{
    width: 290px;
    min-height: 350px;
    background-color: white;
    border-radius: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 25px;
}

.modal-title{
    font-size: 26px;
    color: #FF4D38;
    text-transform: capitalize;
}

.modal-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-option-btn{
    background-color: #00CCFF;
    /* border-color: #00CCFF; */
    /* border-style: solid; */
    color: white;
    /* border-width: 4px; */
    width: 150px;
    height: 45px;
    margin: 0px;
    margin-top:10px;

}

.modal-text{
    text-align: center;
    width: 240px;
    max-height: 300px;
    white-space: pre-wrap;
    overflow-y: scroll;
    word-wrap: break-word;
    /* margin-top: 140px; */
    padding: 0px;
    color: navy;
}

.modal-img{
    max-width: 120px;
    max-height: 120px;
    position: relative;
}