.chat-view{
    width: 100%;
}

.chat-modal-container{
    width: 100%;
    height: 100%;

    /* background-color: rgba(6, 9, 87, 0.781); */

    z-index: 100;
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.chat-bottom-disclaimer{
    text-align: center;
    margin: 10px;
    font-size: 13px;
    color: gray;
}

.chat-modal-bg{
    width: 100%;
    height: 100%;

    background-color: rgba(6, 9, 87, 0.781);

    z-index: 100;
    position:  absolute;
}

.chat-modal{
    background-color: white;
    color: navy;
    width: 300px;
    height: 370px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
    padding: 20px;
    z-index: 200;
}

.text-align-center{
    text-align: center;
}



.conversation{
    padding-bottom: 50px;
    margin-bottom: 100px;
    margin-top: 90px;
}

.chat-send{
    background-color: #00CCFF;
    width: 100px;
    height: 45px;
}

.chat-input{
    height: 45px;
    width: 100%;
    min-width: 100px;
    border-radius: 10px;
    border: none;
    background-color: #F0F0F4;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
    font-size: 14px;
    font-weight: 700;
    border-radius: 50px;
    color: white;
    background-color: #3DD869;;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    /* width: 300px; */
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: navy;;
}

.inputfile:focus + label {
	outline: 1px dotted #000;
	outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.upload-btn-img{
    width: 30px;
    height: 30px;
}

.chat-bottom{
    width: 100%;
    height: 70px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 0px;


    border-top: 1px solid #D9D9D9;

    
}

.chat-send-small{
    display: none;
}

/* @media screen and (max-width: 1200px) {
    
    .chat-input{
        width: calc(400px - 100px - 46px - 40px);
        
    }

    .chat-bottom{
        width: 400px
    }

}

@media screen and (max-width: 850px) {
    
    .chat-input{
        width: calc(360px - 100px - 46px - 40px);
        
    }
    .chat-bottom{
        width: 360px
    }


} */


@media screen and (max-width: 600px) {
    .chat-send{
        display: none;
    }

    .chat-send-small{
        display: inline;

        padding-left: 10px;
        margin: 5px;
        border: none;
        border-radius: 100px;
        cursor: pointer;

        border-radius: 50px;
        color: white;
        background-color: #00CCFF;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        margin: 5px;


    }
}