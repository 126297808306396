.levels{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}



.game-intro{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-right: 5%;
    margin-left: 5%;
    margin-bottom: 1%;
    padding-top: 70px;

}

.game-img{
    width: 200px;
    height: 115px;
    
}

.game-description{
    font-family: 'Helvetica', sans-serif;
    color: lightslategray;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
    width: 300px;
    margin-top: 20px;
    /* max-width: 300px; */
    margin-bottom: 20px;
    text-align:left;
    /* padding-top: 70px; */
}

.extra-description{
    font-family: 'Helvetica', sans-serif;
    color: lightslategray;
    font-weight: bold;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
    /* max-width: 300px; */
    margin-bottom: 20px;
    text-align:center;
    /* padding-top: 70px; */
}

.game-intro-video{
    width: 400px;
    height: 280px;
    margin: 20px;
    border-radius: 16px;
}

.locked-level{
    background-color: #F42F54;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.lock-img{
    width: 40px;
    height: 40px;
}

.locked-level-container{
    
    width: 90px;
    height: 120px;
    margin: 20px;
    
}

@media screen and (max-width: 900px) {
    

    .game-description{
        text-align:center;
        width: 80vw;
    }
}