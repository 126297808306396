.dialogue{
    background-color: #984EFF;
    border-radius: 20px;
    width: 320px;
    height: 175px;
    margin: 0;
    color: white;
    padding: 15px;
}

.dialogue, p{
    margin: 0;
    size: 1em;
}

.dialogue-text{
    background-color: #984EFF;
    border-radius: 20px;
    width: 320px;
    height: 175px;
    margin: 0;
    color: white;
    padding: 15px;
}

.dialogue-container{
    display: flex;
    justify-content: right;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
}

.arrow{
    margin-top: 0;
    margin-left: 40px;
    width: 20px;
}

.left-arrow{
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    margin-bottom: 30px;
}