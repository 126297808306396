.join-wallstreet-view{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}


.create-game-btn{
    background-color: #3DD869;
    border-radius: 8px;
}

.join-game-btn{
    background-color: #00CCFF;
    border-radius: 8px;
}

.join-text{
    color: navy;
    font-weight: bolder;
    max-width: 250px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0px;
}

.join-game-btn:hover{
    cursor: pointer;
}

.create-game-btn:hover{
    cursor: pointer;
}

.in-queue-view{
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    margin-top: 65px;
    color: rgb(17, 30, 107);
}

.change-round-view{
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}

.start-game-btn{
    background-color: rgb(255, 209, 0);
    border-radius: 5px;
    color: rgb(17, 30, 107);
    box-shadow: 0 0px 8px 0 rgb(255, 255, 255);
}

.start-game-btn:hover{
    cursor: pointer;
}

.start-round-btn:hover{
    cursor: pointer;
}

.start-round-btn{
    background-color: rgb(255, 209, 0);
    border-radius: 5px;
    color: rgb(17, 30, 107);
    box-shadow: 0 0px 8px 0 rgb(255, 255, 255);
}