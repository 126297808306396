.stock{
    width: 240px;
    height: 270px;
    background-color: white;
    border-radius: 8px;
    border-style: solid;
    border-color:#111E6B;
    border-width: 4px;
    margin: 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;

    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
}

.stock-sell-btn{
    background-color: #3DD869;
    height: 35px;
    width: 100px;
    border-radius: 5px;
}

.stock-sell-btn:hover{
    cursor: pointer;
}

.wallstret-stock-logo{
    max-width: 150px;
    max-height: 70px; 
    margin-top: 5px;
}

.stock-value{
    text-align: right;
    font-size: 16px;
}
.stock-data{
    text-align: left;
    font-size: 16px;
}

.stock-portfolio{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 80px;
    padding-bottom: 80px;
}

.stock-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 210px;

    font-size: 16px;
    color: #111E6B;
    font-weight: bolder;
    padding-top: 5px;

    padding-bottom: 5px;

    border-bottom: solid 1px #F0F0F4;
    
}

.portfolio-placeholder{
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px; */
    
    text-align: center;
    margin: auto;
    margin-top: 70px;
    width: 250px;
    padding-bottom: 80px;
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 1s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
