.kitchen{
    /* padding-top: 100px; */
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    

    font-size: 55px;
    font-family: 'Helvetica', 'sans-serif';
    font-weight: bolder;
    color: #111E6B;
    margin: 30px;
}

.kitchen-view{
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    text-align: center;
}

.kitchen-instructions{
    text-align: center;
}

.buy-item{
    background-color: #FFD100;
}

@media screen and (max-width: 530px) {
    .kitchen{
        font-size: 35px;
    }
}

