.global-progress-container {
    width: 80%;
    max-width: 692px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin: 20px auto;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
}

.global-progress-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.global-progress-info h2 {
    color: #111E6B;
    font-size: 24px;
    margin: 0;
}

.global-progress-info p {
    color: #111E6B;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
} 


.topic-progress-bar-background{
    width: 100%;
    height: 25px;
    background-color: #00000074;
    border-radius: 50px;
    color: white;
    font-weight: bold;
}


.subject-progress-bar-background{
    width: 100%;
    height: 25px;
    background-color: #00000040;
    border-radius: 50px;
    color: #111E6B;
    font-weight: bold;
}


.topic-progress-bar{
    height: 100%;
    background-color: #3DD869;
    border-radius: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
}