
.game-card{
    width: 200px;
    height: 200px;
    border-style: solid;
    border-width: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 10px;
}

.game-card-img{
    width: 150px;
    height: 85px;
}

.game-card-name{
    font-family: "Helvetica", sans-serif;
    font-weight: bold;
    font-size: 1.7em;
    margin-top: 10px;
    margin-bottom: 10px;
}