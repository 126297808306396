.quiz-question{
    width: 100%;
    padding: 10px;
}

.quiz-prompt{
    color: #111E6B;
    font-weight: bolder;
    margin-top: 0px;
}

.quiz-option{
    background-color: #111E6B;
    color: white;
    border-radius: 5px;
    width: 90%;
    padding: 10px;
    min-height: 38px;
    margin: 10px;
}

.quiz-option:hover{
    cursor: pointer;
    background-color: #0cf;
}

.quiz-results{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #111E6B;
    font-weight: bolder;
    text-align: center;
}

.quiz-results-value{
    font-size: 28px;
    color: #3DD869;
}