.quest-container{
    margin: 20px;
    display: flex;
    flex-direction: column;
}
.quest-level{
    background-color: #984EFF;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    color:white;
    display: flex;
    
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    font-size: 2em;
}

.quest-grade{
    width: 90px;
    height: 30px;;
}