.counter{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.counter-minus{
    width: 44px;
    height: 44px;
    border: solid 3px #FF3B6A;
    border-radius: 7px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.counter-plus{
    width: 44px;
    height: 44px;
    border: solid 3px #3DD869;
    border-radius: 7px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.amount{
    width: 50px;
    height: 44px;
    border: solid 3px #111E6B;
    border-radius: 7px;
    font-size: 24px;
    font-weight: bold;
    color: #111E6B;
    margin-left: 5px;
    margin-right: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    
}

.counter-img{
    width: 23px;
    height: 23px;
}