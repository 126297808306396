.robins-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin:5px;
    height: 25px;
    
}

.robin-coin{
    width: 55px;
    height: 55px;
}

.robin-balance{
    color:#F68E00;
    font-family: "Helvetica", "sans-serif";
    font-size: 30px;
    margin: 0px;
    margin-left: 10px;
    font-weight: bolder;
}

