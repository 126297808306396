.prize-modal-background{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.37);

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    z-index: 5;
}
.prize-modal{
    width: 270px;
    height: 270px;
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    border-radius: 20px;
}
.prize-text{
    max-width: 250px;
    color: #00CCFF;
    font-size: 25px;
    text-align: center;
}

.prize-coin-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
}

.prize-coin-container p {
    color: #FFBD00;
    font-size: 60px;
    margin-left: 10px;
}

.prize-modal-coin{
    width: 80px;
    height: 80px;
}

.prize-modal-button{
    width: 126px;
    height: 51px;
    background-color: #00CCFF;
}
