.course-view-container{
    margin-top: 100px;
    
    font-weight: bold;
    
}

.new-module{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    margin: 20px;
    width: 170px;
    height: 186px;
    background-color: #F42F54;
    background-image: linear-gradient(-45deg, #984EFF, #00CCFF);
    color:#111E6B;
    font-weight: bold;
    border-radius: 20px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    border-width: 6px;
}

.new-module-img{
    max-width: 70px;
    max-height: 70px;
    margin-bottom: 10px;
}

.new-module-name{
    margin: 10px;
    height: 38px;
    color: white;
}

.course-description{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.course-description-text{

    font-weight: bolder;
    color: #111E6B;
    margin: 15px;
    width: 300px;
    text-align: center;
}

.course-description-left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 5px;
}

.video-course{
    border-radius: 15px;
    margin: 5px;
    height: 180px;
    width: 320px;
}


.course-top-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-sessions{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.skill-container{
    display: flex;
    width: 700px;
    height: 270px;
    margin-bottom: 100px;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 16px;
    flex-direction: column;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

}

.skills-header{
    font-size: 30px;
    font-weight: bolder;
    color: #111E6B;
    margin: 15px;
}

/* Cuanto es pantalla pequeña */
@media screen and (max-width: 600px) {
    
    .skill-container{
        width: 300px;
        height: 400px;
        margin: 5px;
    }
}