.activity-container{
    max-width: 820px;
    margin: auto;
}

.activity-top-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 90px;

}

.activity-left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    width: 500px;
    height: 310px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 10px;

    margin-right: 20px;
}

.activity-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 310px;
    margin:  10px;
    margin-left: 20px;
}

.activity-img{
    width: 500px;
    height: 245px;
    border-radius: 10px;
    object-fit: cover
}

.flex-horizontal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.activity-description-text{
    color: #111E6B;
    font-weight: bolder;
    margin-left: 10px;
}

.blue-text{
    color: #111E6B;
    font-weight: bolder;
}

.activity-start-btn{
    background-color: #984EFF;
    border-radius: 8px;
}

.activity-start-btn:hover{
    box-shadow: 0 0px 8px 0 #984EFF;
    cursor: pointer;
}

.mastery-container{
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    width: 280px;
    height: 125px;
    background-color: white;
    border-radius: 10px;
}

.mastery-top{
    width: 255px;
    color: navy;
    font-weight: bolder;
    margin: 10px;
}

.mastery-bottom{
    width: 255px;
    color: navy;
    font-weight: bolder;
    margin: 10px;
}

.activity-update-btn{
    background-color: #3DD869;
    width: 130px;
    border-radius: 8px;
    margin: 0px;
}

.activity-update-btn:hover{
    box-shadow: 0 0px 8px 0 #3DD869;
    cursor: pointer;
}

.mastery-field{
    width: 90px;
    height: 40px;
    border-style: solid;
    padding-left: 0px;
    border-radius: 8px;
    border-width: 4px;
    border-color: #111E6B;
    background-color: #F0F0F4;
    color:#111E6B;
    font-size: 20px;
    font-weight: bolder;
    line-height: 25px;
    text-align: center;
    margin: 0px;
}

.activity-reward-container{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    text-align: center;
    color:#111E6B;
    font-weight: bolder;
    height: 150px;
    width: 280px;

}

.resources-title{
    color:#111E6B;
    font-size: 20px;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 10px;
}

.activity-resource{
    width: 280px;
    height: 60px;
    background-color: white;
    color:#111E6B;
    font-weight: bolder;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 10px;

}

.resources-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.activity-resource:hover{
    cursor: pointer;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.4);
    
}

.resource-icon{
    max-width: 30px;
    max-height: 30px;
    margin: 10px;
}