
.choose-payment{
    margin-top: 120px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 510px;
    width: 320px;
    border-radius: 16px;
}

.payment-type{
    /* max-height: 50px; */
    width: 270px;
    margin-top:10px;
}


.teacher-signup{
    margin-top: 120px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 510px;
    width: 320px;
    border-radius: 16px;

}
.choose-course-img{
    height: 100px;
}

.discount-img{
    max-height: 90px;
    max-width: 90px;
}

.signup-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.signup-title{
    color:#111E6B;
    font-size: 20px;
    font-weight: bolder;
    line-height: 25x;
    max-width: 300px;
    margin: 0px;
    text-align: center;
}

.tarjeta{
    color:#00CCFF;
    font-size: 25px;
    font-weight: bolder;
    line-height: 25x;
    max-width: 310px;
    margin: 0px;
}

.titular{
    color:#00CCFF;
    font-size: 15px;
    font-weight: bolder;
    line-height: 25x;
    max-width: 300px;
    margin: 0px;
}

.whatsapp{
    color:#585858;
    font-size: 15px;
    font-weight: bolder;
    line-height: 25x;
    max-width: 300px;
    margin: 0px;
}

.signup{
    background-color: #3DD869;
    width: 270px;
    height: 50px;
}

.regresar{
    border-style: solid;
    border-width: 6px;
    background-color: white;
    border-color: #00CCFF;
    color: #00CCFF;
    width: 270px;
    height: 50px;
}






/* Sign-up.css */




.signup-container{
    width: 530px;
    height: 320px;
    background-color: white;
    border-radius: 16px;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
    padding-top: 15px;
    padding-bottom: 15px;
}

.stripe-container{
    width: 100vw;
    height: 100vh;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    /* flex-direction: column; */
}

.payform-container{
    width: 530px;
    height: 320px;
    background-color: white;
    border-radius: 16px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2);
}

.tarjeta-oxxo{
    width: 300px;
}

.elements-container{
    width: 500px;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
}

.signup-text{
    color:#111E6B;
    font-size: 20px;
    font-weight: bolder;
    line-height: 25px;
    max-width: 290px;
    margin: 0px;
    margin-top: 10px;
}

.bottom-signup-container{
    width: 530px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 500px;

}

.hoc-container{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 80px;

}

.course-icon{
    max-width: 125px;
    max-height: 95px;
    margin: 20px;
}

.signup-img{
    max-height:160px;
    max-width: 160px;
    margin: 10px; 
}


.price-total{
    display: inline-block;
    width: 100%;
    height: 60px;
}

.new-price{
    font-size: 25px;
    line-height: 25px;
    font-weight: bolder;
    margin: 0px;
    margin-bottom: 10px;
    color: #F42F54;

    text-align: center;
}
.final-price{
    font-size: 25px;
    line-height: 25px;
    font-weight: bolder;
    margin: 0px;
    margin-bottom: 10px;
    color: #F42F54;

    text-align: center;
}

.old-price{
    font-size: 25px;
    line-height: 25px;
    font-weight: bolder;
    margin: 0px;
    margin-bottom: 5px;
    color: #111E6B;
    text-decoration: line-through;

    text-align: center;
}


.card-field{
    box-sizing: border-box;
  
    height: 40px;
  
    padding: 10px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
  
    box-shadow: 0 1px 3px 0 #C4C4C4;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #6A6A6A;
}

.whatsapp-send{
    background-color: #3DD869;
    color: white;
    width: 270px;
    height: 50px;
}

.course-title-signup{
    margin-top: 80px;
    margin-bottom: 10px;
    max-width: 80vw;
    color: #585858;
    font-size: 20px;
    font-weight: bolder;
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
}

.pay-btn{
    border-radius: 70px;
    border-style: none;

    height: 55px;
    color: white;
    font-family: 'Helvetica', sans-serif;
    font-weight: bold;
    font-size: 1.3em;

    background-color: #00CCFF;
    width: 270px;
    height: 50px;
    margin-top: 15px;
}

.checkout-price-display{
    font-size: 25px;
    line-height: 40px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 5px;
    color: #111E6B;
    /* text-decoration: line-through; */
}

.security-img{
    width: 150px;
    /* margin-top: 10px; */
}

.extra-card-field-text{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 15px;
    text-align: left;
    font-weight: 600;
    color: #00CCFF;
    margin: 0px;
    margin-bottom: 5px;
}


.extra-card-fields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 500px;
    
}

.StripeElement {
    box-sizing: border-box;
  
    height: 40px;
  
    padding: 10px 12px;
  
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
  
    box-shadow: 0 1px 3px 0 #C4C4C4;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  /* @media screen and (max-width: 600px) { */
    .signup-container{
        height: 530px;
        width: 320px;
    }

    .bottom-signup-container{
        width: 320px;
        height: 500px;
    }

    .signup{
        margin-top:10px;
    }

    input.textfield{
        margin-top:10px;
    }

    .payform-container{
        width: 320px;
        height: 530px;
    }

    .extra-card-fields{
        flex-direction: column;
        flex-wrap: wrap;
        height: 140px; 
    }

    .card-field{
        width: 300px;
    }

    .elements-container{
        width: 300px;
    }

/* } */