.nav-bar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 100;
}

.view-title{
    font-family: 'Helvetica', sans-serif;
    color: #3DD87F;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 2.3em;
}

.super-modal{
    z-index: 1000;
    position: fixed;
    background-color: none;
    pointer-events: none;
    left: auto;
    height: 100vh;
    width: 100vw;
    display: flex;

    justify-content: center;
    align-items: center;


}
.loading{
    width: 150px;
    height: 150px;
    border-radius: 16px;
    /* border-color: navy; */
    /* border-bottom-width: 4px; */
    /* border-style: solid; */
    background-color: white;

    display: flex;

    justify-content: center;
    align-items: center;


    -webkit-box-shadow: 6px 10px 103px -15px rgb(55, 55, 55);
    -moz-box-shadow: 6px 10px 103px -15px  rgb(55, 55, 55);
    box-shadow: 6px 10px 103px -15px rgb(55, 55, 55);

}

.loading-img{
    width: 100px;
    height: 100px;
}
