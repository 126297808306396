.gourmet-nav{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.gourmet-tabs{
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.quit{
    position: absolute;
    top: 0;
    left: 0px;
}

.cash-container{
    width: 144px;
    height: 77px;
    background-color: white;
    border-radius: 0px 0px 0px 16px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    position: fixed;
    top: 0;
    right: 0;
}

.cash-img{
    width: 50px;
    margin: 5px;
}

.cash{
    color: #3DD869;
    font-size: 24px;
    font-weight: bolder;
}

.quit{
    width: 45px;
    height: 45px;
    margin: 5px;
}

/* Cuanto es pantalla pequeña */
@media screen and (max-width: 530px) {
    .cash-container{
        width: 77px;
        padding: 5px;
    }
  }