.fin-glossary-video{
    border-radius: 10px;
    margin: 10px;
    width: calc(45vw);
    height: calc((45vw)/1.77);
}

.fin-glossary{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    flex-wrap: wrap;

    font-weight: bold;
}


.session-view-container{
    display: flex;
    /* justify-content: space-around; */
    align-items: flex-start;
    /* margin: 5%; */
    margin-top: 65px;
    flex-direction: row;
    flex-wrap: wrap;
    flex-direction: column;

    font-weight: bold;

}

.nav-bar-container{
    z-index: 3;
}

.session-view{
    margin-top: 65px;
}

.session-view-left {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    width: calc(100% - 500px);
    left: 0px;
    padding: 20px;

    border-right: 1px solid #D9D9D9;
    background-color: white;
}

.session-view-right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* margin: 20px; */
    height: 100%;
    margin-top: 0px;
    /* min-width: 350px;
    max-width: 450px; */

    width: 500px;
    top:65px;
    right: 0px;
    position: fixed;
    background-color: white;

}


.session-view-locked{
    margin:65px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.session-text-centered{

    text-align: center;
}

.register-btn{
    background-color: #3DD869;
}

.session-locked-promt{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.session-locked-title{
    color: #984EFF;
    font-size: 40px;
    text-align: center;
    margin-bottom: 20px;
}

.session-locked-text{
    font-size: 30px;
    text-align: center;
}



.robin-session-container{
    margin: 15px;
    margin-bottom: 0px;
    top: 0px;
    right: 0px;
    position: fixed;
    z-index: 100;
}

.berry-session-container{
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    flex-direction: column;
}

.robin-session-text{
    margin-top: 10px;
    text-align: center;
    color: gray;
    font-size: 13px;
    margin-bottom: 0px;
    
}



.homework-img{
    max-width: 240px;
    max-height: 240px;
    border-radius: 15px;
}

.session-video{
    width: 1000px;
    height: 40vw;
    border-radius: 20px;

    width: calc(95vw - 500px);
    height: calc((95vw - 500px)/1.77);
    border-radius: 15px;


    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.5);
    /* border: 5px solid #984EFF; */
}

.session-title{
    font-size: 24px;
    margin-top: 10px;
    color:#111E6B;

}

.session-description{
    margin-top: 5px;
    font-weight: normal;
    color:#111E6B;
}

.steps-container{
    height: 100px;
    width: 1000px;
    background-color: #F0F0F4;



    display: flex;
    overflow-x: scroll;

    
    flex-wrap: nowrap;
    overflow-x: auto;

}

.step-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.side-tutor-chat::-webkit-scrollbar {
    display: none;
  }

.side-tutor-chat{
    background-color: #F0F0F4;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border: 5px solid #984EFF;
}

.side-group-chat{
    background-color: #F0F0F4;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    border: 5px solid #00CCFF;
}


.side-chat-tab-img{
    max-width: 30px;
    max-height: 30px;  
    margin: 10px; 
}

.side-group-chat-tab{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #00CCFF;
    height: 45px;
    width:50%;

    border-radius: 10px 10px 0px 0px;

    position: relative;
}

.side-tutor-chat-tab{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #984EFF;
    height: 45px;
    width:50%;

    border-radius: 10px 10px 0px 0px;

    position: relative;
}

.side-chat-tabs{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    font-size: 18px;
    color: white;
    margin-top: 10px;
}

.chat-big-buttons{
    display: none;
}

.chat-input{
    width: calc(500px - 100px - 46px - 40px);
    
}

@media screen and (max-width: 1200px) {
    
    .chat-input{
        width: calc(400px - 100px - 46px - 40px);
        
    }

    .chat-bottom{
        width: 400px
    }

}

@media screen and (max-width: 850px) {
    
    .chat-input{
        width: calc(360px - 100px - 46px - 40px);
        
    }
    .chat-bottom{
        width: 360px
    }


}

@media screen and (max-width: 1200px) {
    
    .session-view-left {
        
        width: calc(100% - 400px);;
        
        background-color: rgb(240, 240, 244);
        
    }
    
    .session-view-right{
        
        margin-top: 0px;
        
        width: 400px;
    
    }

    .session-video{
        width: calc(95vw - 400px);
        height: calc((95vw - 400px)/1.77);

    }

}

@media screen and (max-width: 850px) {
    
    .session-view-left {
        
        width: calc(100% - 360px);;
    
        
    }
    
    .session-view-right{
        
        margin-top: 0px;
        
        width: 360px;
    
    }

    .session-video{
        width: calc(95vw - 360px);
        height: calc((95vw - 360px)/1.77);


    }

}

@media screen and (max-width: 730px) {
    .session-view-container{
        
        flex-direction: column-reverse;
    
    }
    .session-view-left {
        
        width: 100vw;
    
        border-right: none;
        
    }
    
    .session-view-right{
        
        margin-top: 0px;
        
        width: 100vw;
        position: inherit;
    
    }

    .session-video{
        width: 100vw;
        height: calc((100vw)/1.77);
        border-radius: 20px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 20px;
    }

    .chat-big-buttons{
        display: inline-block;
    }

    .side-tutor-chat{
        display: none;
    }

    .side-group-chat{
        display: none;
    }
    .side-chat-tabs{
        display: none;
    }
}

/* Cuando es pantalla pequeña */
@media screen and (max-width: 530px) {
    

    .session-locked-title{
        display: none;
    }

    .session-view-left {
        display: flex;
        flex-direction: column;
        justify-content:center;
        align-items: center;
    }
  }