/* .welcome {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(-45deg, #FF4D38, #F42F54);
    display: flex;



} */

.welcome-logo {
    width: 130px;
    margin-top: 50px;



    /* height: 45px;    
    display: block; */
}

.welcome-box {
    background-color: white;
    width: 300px;
    height: 520px;
    margin: auto;
    border-radius: 20px;
    margin-top: 20px;
    display: flex;
    padding-bottom: 10px;

    justify-content: center ;
    align-items: center;
    flex-direction: column;
}

.welcome-text {
    font-family: 'Helvetica';
    font-weight: bold;
    margin: 35px;
    margin-top: 120px;
    text-align: left;
    color: navy;
    line-height: 25px;
    font-size: 1.3em;
    vertical-align: top;
}