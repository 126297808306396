.textfield{
    width: 250px;
    height: 40px;
    border-style: solid;
    border-radius: 16px;
    border-width: 6px;
    margin-bottom: 10px;
    padding-left: 10px;
    color:#111E6B;
    font-size: 20px;
    font-weight: bolder;
    line-height: 25px;
}