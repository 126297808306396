.project-card{
    height: 380px;
    width: 290px;
    margin: 10px;
    background-color: white;
    border-radius: 10px;

    font-weight: bolder;
    box-shadow: 0 0px 8px 0 rgb(0 0 0 / 20%);
}

.project-card-details{
    margin: 10px;
    margin-top: 5px;
}

.project-card-img{
    width: 290px;
    height: 190px;
    border-radius: 10px;
}

.project-title{
    color: navy;
    font-size: 18px;
}

.project-card-horizontal{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.project-time{
    margin-right: 10px;
    color: gray;
    font-size: 14px;

    margin-top: 8px;
}

.project-age{
    margin-right: 10px;
    color: gray;
    font-size: 14px;
    margin-top: 8px;
}

.project-subtitle{
    font-size: 14px;
    color: #00CCFF;
    margin-top: 8px;
    margin-bottom: 4px;
}

.project-subject{
    margin-right: 5px;
    background-color: #F0F0F4;
    border-radius: 3px;
    font-size: 14px;
    padding: 4px;
    color: navy;
}
.project-tag{
    margin-right: 5px;
    background-color: #F0F0F4;
    border-radius: 3px;
    font-size: 14px;
    padding: 4px;
    color: navy;
}